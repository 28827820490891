<template>
  <div>
    <slot
      name="activator"
      :open="open">
      <CyMenu
        v-model="show.menu"
        offset-y
        v-bind="menuProps">
        <template #activator="{ on }">
          <CyButton
            variant="primary"
            theme="secondary"
            icon="add"
            v-on="on">
            {{ $t('addMetric') }}
          </CyButton>
        </template>

        <v-list
          class="menu"
          three-line
          dense>
          <div
            v-for="(widgets, group, index) in $static.KPIS_SETTINGS"
            :key="`subheader-${group}`">
            <v-divider
              v-if="index"
              :key="`divider-${group}`"/>
            <v-subheader>
              {{ group }}
            </v-subheader>
            <v-list-item
              v-for="({ label, legend, type, widget }) in widgets"
              :key="`${type}-${widget}`"
              v-hover-siblings
              @click="open(type, widget)">
              <v-list-item-content>
                <v-list-item-title>{{ $t(label) }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t(legend) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </CyMenu>
    </slot>

    <CyModal
      v-if="show.dialog"
      modal-type="create"
      :header-title="modalTitle"
      :cancel-btn-func="close"
      small>
      <form
        class="add-widget-form"
        @submit.prevent="save">
        <CyAlert
          theme="error"
          :content="errors"/>
        <CyAlert
          v-if="kpi.error"
          theme="error"
          :content="`${$t('kpis.anErrorOccurred')} ${kpi.error}`"/>
        <section class="mt-3">
          <template v-if="KPISetting">
            <h2 class="h5 mb-2">
              {{ $t(KPISetting.label) }}
            </h2>
            <p class="mb-4">
              {{ $t(KPISetting.legend) }}
            </p>

            <v-text-field
              v-model="$v.form.name.$model"
              :label="$t('metricName')"
              required
              class="required-field"
              :error-messages="nameErrors"
              @blur="$v.form.name.$touch()"/>

            <v-select
              v-if="KPISetting.extraFields.includes('env') && !KPISetting.extraFields.includes('pipeline')"
              v-model="$v.form.environmentCanonical.$model"
              :items="envs"
              :label="$t('forms.fieldOrgEnv')"
              item-value="canonical"
              item-text="name"
              required
              class="required-field"
              :error-messages="envErrors"
              :disabled="!isCreationMode"
              :menu-props="{ offsetY: true }"
              @blur="$v.form.environmentCanonical.$touch()">
              <template #selection="{ item: { canonical, icon, color } }">
                <span class="d-flex align-center">
                  <CyAvatar
                    :item="{ canonical, icon, color }"
                    :disabled="!isCreationMode"
                    class="mr-1"
                    sm/>
                  {{ canonical }}
                </span>
              </template>
              <template #item="{ item: { canonical, icon, color } }">
                <span class="d-flex align-center">
                  <CyAvatar
                    :item="{ canonical, icon, color }"
                    class="mr-1"
                    sm/>
                  {{ canonical }}
                </span>
              </template>
            </v-select>

            <v-select
              v-if="KPISetting.extraFields.includes('pipeline')"
              v-model="pipeline"
              :items="pipelines"
              item-value="id"
              item-text="name"
              return-object
              :label="$t('untranslated.pipeline')"
              required
              class="required-field"
              :error-messages="pipelineErrors"
              :disabled="!isCreationMode"
              :menu-props="{ offsetY: true }"
              @blur="$v.form.pipelineName.$touch()">
              <template #selection="{ item: { environment, paused, name } }">
                <span class="pipeline-name d-flex align-center">
                  <CyAvatar
                    :item="environment"
                    :disabled="!isCreationMode"
                    class="mr-1"
                    sm/>
                  <span class="pipeline-name__text">
                    {{ name }}
                  </span>
                  <CyTag
                    v-if="paused"
                    class="ml-1"
                    small
                    variant="default">
                    {{ $t('pipeline.paused') }}
                  </CyTag>
                </span>
              </template>
              <template #item="{ item: { environment, paused, name } }">
                <span class="pipeline-name d-flex align-center">
                  <CyAvatar
                    :item="environment"
                    class="mr-1"
                    sm/>
                  <span class="pipeline-name__text">
                    {{ name }}
                  </span>
                  <CyTag
                    v-if="paused"
                    class="ml-1"
                    small
                    variant="default">
                    {{ $t('pipeline.paused') }}
                  </CyTag>
                </span>
              </template>
            </v-select>

            <v-select
              v-if="KPISetting.extraFields.includes('job')"
              v-model="$v.form.jobName.$model"
              :items="jobs"
              item-value="name"
              :label="$t('untranslated.job')"
              :loading="loading.jobs"
              required
              class="required-field"
              :error-messages="jobErrors"
              :disabled="!isCreationMode"
              :menu-props="{ offsetY: true }"
              @blur="$v.form.jobName.$touch()">
              <template #selection="{ item: { name } }">
                {{ name }}
              </template>
              <template #item="{ item: { name } }">
                {{ name }}
              </template>
            </v-select>

            <v-input
              v-if="KPISetting.extraFields.includes('env_order')"
              :label="$t('envOrder')"
              :hint="$t('envOrderHint')"
              :error-messages="envOrderErrors"
              persistent-hint
              hide-details
              required
              class="env-input required-field">
              <div class="draggable">
                <draggable
                  v-if="$v.form.envOrder.$model.length"
                  v-model="$v.form.envOrder.$model">
                  <div
                    v-for="(env, index) in $v.form.envOrder.$model"
                    :key="index"
                    :test="index">
                    <v-chip
                      class="mb-1"
                      close
                      outlined
                      @click:close="removeEnv(index)">
                      <v-icon
                        size="20"
                        class="draggable__handle">
                        drag_indicator
                      </v-icon>
                      <CyAvatar
                        :item="getEnv(env)"
                        class="mx-1"
                        sm/>
                      <span class="mx-1">
                        {{ env }}
                      </span>
                    </v-chip>
                  </div>
                </draggable>

                <div
                  v-else
                  class="draggable__empty">
                  {{ $t('envOrderEmpty') }}
                </div>

                <CyMenu offset-y>
                  <template #activator="{ on }">
                    <CyButton
                      class="mt-3"
                      icon="add"
                      variant="secondary"
                      theme="primary"
                      sm
                      :disabled="!availableEnvs.length"
                      v-on="on">
                      {{ $t('btnAddEnv') }}
                    </CyButton>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="env in availableEnvs"
                      :key="env.canonical"
                      @click="addEnv(env)">
                      <v-list-item-avatar>
                        <CyAvatar
                          :item="env"
                          class="mr-1"
                          sm/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ env.canonical }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </CyMenu>
              </div>
            </v-input>
          </template>
        </section>
      </form>

      <template slot="modal-buttons">
        <CyButton
          v-if="!isCreationMode"
          :disabled="!$cycloid.permissions.canDisplay('DeleteKPI', kpi.canonical)"
          variant="tertiary"
          theme="error"
          class="mr-auto"
          :loading="loading.remove"
          @click="remove">
          <v-icon>delete</v-icon>
          {{ $t('forms.btnDelete') }}
        </CyButton>

        <CyButton
          theme="secondary"
          variant="secondary"
          @click="close">
          <v-icon>close</v-icon>
          {{ $t('forms.btnClose') }}
        </CyButton>

        <CyButton
          :disabled="!canSave"
          theme="secondary"
          class="ml-4"
          :loading="loading.save"
          @click="save">
          <v-icon>check</v-icon>
          {{ confirmBtnText }}
        </CyButton>
      </template>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { KPIS_SETTINGS, getKPISetting } from '@/utils/config/kpis'
import draggable from 'vuedraggable'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsWidget',
  components: {
    draggable,
  },
  props: {
    projectCanonical: {
      type: String,
      required: true,
    },
    kpi: {
      type: Object,
      default: () => ({}),
    },
    menuProps: {
      type: Object,
      default: () => ({
        'offset-y': true,
        bottom: true,
        left: true,
        origin: 'top right',
      }),
    },
  },
  data: ({ kpi }) => ({
    show: {
      dialog: false,
      menu: false,
    },
    loading: {
      jobs: false,
      save: false,
      remove: false,
    },
    form: {
      canonical: _.get(kpi, 'canonical', null),
      type: _.get(kpi, 'type', ''),
      widget: _.get(kpi, 'widget', ''),
      name: _.get(kpi, 'name', ''),
      environmentCanonical: _.get(kpi, 'environment_canonical', ''),
      pipelineName: _.get(kpi, 'pipeline_name', ''),
      jobName: _.get(kpi, 'job_name', ''),
      envOrder: _.clone(_.get(kpi, 'config.envs', [])),
    },
    jobs: [],
  }),
  validations () {
    return {
      form: {
        type: {
          required,
        },
        widget: {
          required,
        },
        name: {
          required,
          minLength: minLength(3),
        },
        pipelineName: {
          required: requiredIf(function () {
            return this.KPISetting?.extraFields.includes('pipeline')
          }),
        },
        environmentCanonical: {
          required: requiredIf(function () {
            return this.KPISetting?.extraFields.includes('env')
          }),
        },
        jobName: {
          required: requiredIf(function () {
            return this.KPISetting?.extraFields.includes('job')
          }),
        },
        envOrder: {
          required: requiredIf(function () {
            return this.KPISetting?.extraFields.includes('env_order')
          }),
        },
      },
    }
  },
  computed: {
    ...mapState('organization/project', {
      pipelines: (state) => state.pipelines,
    }),
    ...mapState('organization/project/kpi', {
      errors: (state) => state.errors.kpi,
    }),
    ...mapGetters('organization/project', [
      'envs',
    ]),
    isCreationMode () {
      return _.isEmpty(this.kpi)
    },
    saveAction () {
      return this.isCreationMode
        ? this.CREATE_KPI
        : this.UPDATE_KPI
    },
    canSave () {
      return this.isCreationMode
        ? this.$cycloid.permissions.canDisplay('CreateKPI') && !this.$v.$invalid
        : this.$cycloid.permissions.canDisplay('UpdateKPI') && !this.$v.$invalid && this.$hasDataChanged('form')
    },
    modalTitle () {
      return this.isCreationMode
        ? this.$t('addMetric')
        : this.$t('editMetric')
    },
    confirmBtnText () {
      return this.isCreationMode
        ? this.$t('addMetric')
        : this.$t('forms.btnSave')
    },
    KPISetting () {
      const { type, widget } = this.form
      return getKPISetting({ type, widget })
    },
    pipeline: {
      get () {
        return this.pipelines.find(({ name }) => name === this.form.pipelineName)
      },
      set ({ name = '', environment = '' }) {
        this.form.pipelineName = name
        this.form.environmentCanonical = environment.canonical
        if (!_.isEmpty(name)) this.getJobs(name)
        else this.jobs = []
      },
    },
    nameErrors () {
      const errors = []
      const { $dirty, required, minLength } = this.$v.form.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!minLength) errors.push(this.$t('forms.fieldMinLength', { number: 3 }))
      return errors
    },
    envErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.environmentCanonical
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    pipelineErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.pipelineName
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    jobErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.jobName
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    envOrderErrors () {
      const errors = []
      const { $dirty, required } = this.$v.form.envOrder
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    availableEnvs () {
      return _.filter(this.envs, (env) => !_.includes(this.form.envOrder, env.canonical))
    },
    $static () {
      return {
        KPIS_SETTINGS,
      }
    },
  },
  watch: {
    KPISetting: {
      handler (setting) {
        if (this.isCreationMode && !_.isEmpty(setting)) {
          const name = this.$t(setting.label)
          this.form.name = name

          if (this.KPISetting?.extraFields.includes('env_order') && this.envs.length < 6) {
            this.form.envOrder = _.map(this.envs, 'canonical')
          }
        }
        this.$v.$reset()
      },
    },
    'form.pipelineName': {
      handler () {
        this.form.jobName = ''
        this.$v.form.jobName.$reset()
      },
    },
  },
  async created () {
    await this.$evaluateUserActions(['DeleteKPI', 'CreateKPI', 'UpdateKPI'])
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT_PIPELINES',
    ]),
    ...mapActions('organization/project/kpi', [
      'CREATE_KPI',
      'UPDATE_KPI',
      'DELETE_KPI',
    ]),
    ...mapMutations('organization/project/kpi', [
      'SET_KPI_ERRORS',
      'CLEAR_KPI_ERRORS',
    ]),
    async getJobs (pipelineCanonical) {
      this.$toggle.loading.jobs(true)
      const { data, errors } = await this.$cycloid.ydAPI.getJobs(this.orgCanonical, this.projectCanonical, pipelineCanonical) || {}
      if (errors) this.SET_KPI_ERRORS({ errors })
      else this.jobs = data
      this.$toggle.loading.jobs(false)
    },
    async save () {
      this.$toggle.loading.save(true)
      const config = this.getKPIConfig()
      await this.saveAction(config)
      this.$toggle.loading.save(false)
      if (_.isEmpty(this.errors)) this.close()
    },
    getKPIConfig () {
      const { name, environmentCanonical, pipelineName, jobName, envOrder, type, widget } = this.form
      const { extraFields } = this.KPISetting
      const canonical = _.$get(this.form, 'canonical', this.$getSlug(`${new Date().getTime()} ${name}`))
      return {
        name,
        canonical,
        type,
        widget,
        project_canonical: this.projectCanonical,
        ...(extraFields.includes('pipeline') ? { pipeline_name: pipelineName } : {}),
        ...(extraFields.includes('job') ? { job_name: jobName } : {}),
        ...(extraFields.includes('env') ? { environment_canonical: environmentCanonical } : {}),
        ...(extraFields.includes('env_order') ? { config: { envs: envOrder } } : {}),
      }
    },
    async remove () {
      this.$toggle.loading.remove(true)
      await this.DELETE_KPI(this.kpi)
      this.$toggle.loading.remove(false)
      if (_.isEmpty(this.errors)) this.close()
    },
    async  open (type = null, widget = null) {
      if (!_.isNil(type)) this.form.type = type
      if (!_.isNil(widget)) this.form.widget = widget
      this.$toggle.show.dialog(true)
      if (!_.includes(['code_coverage', 'time_to_release'], this.form.type)) await this.GET_PROJECT_PIPELINES()
      if (!_.isEmpty(this.form.pipelineName)) await this.getJobs(this.form.pipelineName)
    },
    close () {
      this.$toggle.show.dialog(false)
      this.CLEAR_KPI_ERRORS()
      this.$resetData('form')
    },
    getEnv (envCanonical) {
      return _.find(this.envs, { canonical: envCanonical })
    },
    addEnv (env) {
      this.form.envOrder.push(env.canonical)
    },
    removeEnv (index) {
      this.form.envOrder.splice(index, 1)
    },
  },
  i18n: {
    messages: {
      en: {
        addMetric: 'Add metric',
        btnAddEnv: 'Add an environment',
        editMetric: 'Edit metric',
        envOrder: 'Environments order',
        envOrderHint: 'Add the environments you want to track and reorder them to match the logical order they have in your development flow. Drag and drop the environments to reorder them, with the starting environment placed at the top.',
        envOrderEmpty: 'No environment selected. Add one by clicking on the button below.',
        metricName: 'Metric name',
      },
      es: {
        addMetric: 'Agregar métrica',
        btnAddEnv: 'Añadir un entorno',
        editMetric: 'Editar métrica',
        envOrder: 'Orden de entornos',
        envOrderHint: 'Agregue los entornos que desea rastrear y reordenarlos para que coincidan con el orden lógico que tienen en su flujo de desarrollo. Arrastre y suelte los entornos para reordenarlos, con el entorno inicial colocado en la parte superior.',
        envOrderEmpty: 'No se seleccionó ningún entorno. Agregue uno haciendo clic en el botón de abajo.',
        metricName: 'Nombre de métrica',
      },
      fr: {
        addMetric: 'Ajouter une métrique',
        btnAddEnv: 'Ajouter un environnement',
        editMetric: 'Éditer la métrique',
        envOrder: 'Ordre des environnements',
        envOrderHint: 'Ajoutez les environnements que vous souhaitez suivre et organisez-les dans l\'ordre logique dans votre process de développement. Glissez/déposez les environnements, et placez l\'environnement de départ en haut.',
        envOrderEmpty: 'Aucun environnement sélectionné. Ajoutez-en un en cliquant sur le bouton ci-dessous.',
        metricName: 'Nom de la métrique',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.add-widget-form {
  margin-top: 16px;
  text-align: left;

  .pipeline-name {
    max-width: 100%;

    &__text {
      display: inline-block;
      margin-right: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.env-input {
  margin-top: 4px;

  ::v-deep {
    .v-label {
      font-size: $font-size-sm;
    }

    .v-input__slot {
      flex-direction: column;
      align-items: stretch;
    }

    .v-chip {
      display: flex;
      border-radius: 4px;
      cursor: move;

      &.v-chip--outlined {
        border-width: 1px;
        border-color: map.get($slate-grey, "light-4");

        &.v-chip {
          background-color: cy-get-color("white") !important;
        }
      }

      [aria-label="Close"] {
        position: absolute;
        right: 10px;
      }
    }
  }
}

.draggable {
  padding: 8px;
  border-radius: 8px;
  background-color: cy-get-color("primary", "light-5");

  &__handle {
    margin-right: 4px;
    margin-left: -8px;
  }

  &__remove {
    margin-right: -8px;
    margin-left: 12px;
    transition: 0.2s ease opacity;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__empty {
    padding: 16px 0;
    color: cy-get-color("primary", "light-1");
    font-size: $font-size-default;
    text-align: center;
  }
}

.menu {
  max-width: 260px;

  .v-menu__content &.v-list--dense ::v-deep {
    .v-list-item {
      min-height: 0;
    }

    .v-list-item__title {
      font-weight: $font-weight-bolder;
    }

    .v-list-item__subtitle {
      font-size: $font-size-sm !important;
    }

    .v-subheader {
      align-items: flex-end;
      height: 30px;
      padding-bottom: 4px;
      padding-left: calc(0.5em + 16px);
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      letter-spacing: 1.05px;
      text-transform: uppercase;
    }
  }
}

</style>
